
import { defineComponent, toRef, computed } from "vue";

export default defineComponent({
  name: "table-widget-2",
  components: {},
  props: {
    title: String,
    subtitle: String,
    toolbarButtonLabel: String,
    urlPath: String,
    collection: Array,
  },
  setup(props) {
    const title = toRef(props, "title");
    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitle = toRef(props, "subtitle");
    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const toolbarButtonLabel = toRef(props, "toolbarButtonLabel");
    const toolbarButtonLabelText = computed(() => {
      return toolbarButtonLabel.value ? toolbarButtonLabel.value : "Ver Todos";
    });

    const urlPath = toRef(props, "urlPath");
    const urlPathText = computed(() => {
      return urlPath.value ? urlPath.value : "";
    });

    const collection = toRef(props, "collection");
    const collectionData = computed(() => {
      return collection.value ? collection.value : "";
    });

    return {
      titleText,
      subtitleText,
      toolbarButtonLabelText,
      urlPathText,
      collectionData,
    };
  },
});
